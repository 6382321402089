.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .form_builder .form_editor{
  //background-color: red;

} */

p{
  margin-bottom: 0px !important;
}


.form_builder .react-form-builder .react-form-builder-preview{
  position: absolute;
  top: 11%;
}


.form_builder .jumbotron{
  height: 720px;
  max-height: 720px;
  overflow: scroll;

}

.form_builder .col-md-8{
  position: relative;
  top: -227px;

}

.mainInfo h3{
  font-size: 23px;
}

