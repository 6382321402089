/* body {
    margin: 0;
  }
  
  .App {
    font-family: sans-serif;
    text-align: center;
  } */

/* .flex {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.header {
  height: 300px;
  background-color: #202020;
  color: white;
}

.nav {
  /* height: 80px;
  background-color: #9a9a9a; */
  /* width: 100%; */
}

.sticky {
  position: fixed;
  top: 0;
  width: 20%;
  z-index: 1;
}

.content {
  background-color: white;
}
